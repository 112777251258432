import { createRouter, createWebHashHistory } from "vue-router";
import { useUserStore } from "../stores/UserStore";
const routes = [
  {
    name: "HomePage",
    path: "/",
    component: () => import("@/pages/HomePage"),
  },
  {
    name: "LoginPage",
    path: "/login",
    component: () => import("@/pages/LoginPage"),
  },
  {
    name: "RegisterPage",
    path: "/register",
    component: () => import("@/pages/RegisterPage"),
  },
  {
    name: "DeviceListPage",
    path: "/devices",
    component: () => import("@/pages/Devices/ListPage"),
  },
  {
    name: "DeviceAddPage",
    path: "/device/add",
    component: () => import("@/pages/Devices/AddPage"),
  },
  {
    name: "DeviceDetailPage",
    path: "/device/:id",
    component: () => import("@/pages/Devices/DetailPage"),
  },
  {
    name: "DeviceEditPage",
    path: "/device/edit/:id",
    component: () => import("@/pages/Devices/EditPage"),
  },
  {
    name: "StationListPage",
    path: "/stations",
    component: () => import("@/pages/Stations/ListPage"),
  },
  {
    name: "DealersStationListPage",
    path: "/dealer_stations",
    component: () => import("@/pages/Stations/DealersStationListPage.vue"),
  },
  {
    name: "DealersStationAddPage",
    path: "/dealer_station/add",
    component: () => import("@/pages/Stations/DealersStationAddPage.vue"),
  },
  {
    name: "StationAddPage",
    path: "/station/add",
    component: () => import("@/pages/Stations/AddPage"),
  },
  {
    name: "StationEditPage",
    path: "/station/edit/:id",
    component: () => import("@/pages/Stations/EditPage"),
  },
  {
    name: "DealersStationEditPage",
    path: "/dealer_station/edit/:id",
    component: () => import("@/pages/Stations/DealersStationEditPage.vue"),
  },
  {
    name: "StationQueuePage",
    path: "/station_queue_system/:id",
    component: () => import("@/pages/Stations/StationQueuePage.vue"),
  },
  {
    name: "VehicleListPage",
    path: "/vehicles",
    component: () => import("@/pages/Vehicles/ListPage"),
  },
  {
    name: "VehicleAddPage",
    path: "/vehicle/add",
    component: () => import("@/pages/Vehicles/AddPage"),
  },
  {
    name: "VehicleEditPage",
    path: "/vehicle/edit/:plate",
    component: () => import("@/pages/Vehicles/EditPage"),
  },
  {
    name: "VehicleDetailPage",
    path: "/vehicle/:id",
    component: () => import("@/pages/Vehicles/DetailPage"),
  },
  {
    name: "DriverListPage",
    path: "/drivers",
    component: () => import("@/pages/Drivers/ListPage"),
  },
  {
    name: "DriverAddPage",
    path: "/driver/add",
    component: () => import("@/pages/Drivers/AddPage"),
  },
  {
    name: "DriverEditPage",
    path: "/driver/edit/:id",
    component: () => import("@/pages/Drivers/EditPage"),
  },
  {
    name: "DriverDetailPage",
    path: "/driver/:id",
    component: () => import("@/pages/Drivers/DetailPage"),
  },
  {
    name: "OwnersListPage",
    path: "/owners",
    component: () => import("@/pages/Owners/ListPage"),
  },
  {
    name: "OwnersAddPage",
    path: "/owner/add",
    component: () => import("@/pages/Owners/AddPage"),
  },
  {
    name: "OwnerEditPage",
    path: "/owner/edit/:id",
    component: () => import("@/pages/Owners/EditPage"),
  },
  {
    name: "OwnersDetailPage",
    path: "/owner/:id",
    component: () => import("@/pages/Owners/DetailPage"),
  },
  {
    name: "TestPage",
    path: "/test",
    component: () => import("@/pages/TestPage"),
  },
  {
    name: "PageListPage",
    path: "/pages",
    component: () => import("@/pages/Authorities/PageListPage"),
  },
  {
    name: "DealerListPage",
    path: "/dealers",
    component: () => import("@/pages/Dealers/ListPage"),
  },
  {
    name: "DealersAddPage",
    path: "/dealers/add",
    component: () => import("@/pages/Dealers/AddPage.vue"),
  },
  {
    name: "DealerEditPage",
    path: "/dealer/edit/:id",
    component: () => import("@/pages/Dealers/EditPage.vue"),
  },
  {
    name: "DealerDetailPage",
    path: "/dealer/detail/:id",
    component: () => import("@/pages/Dealers/DetailPage.vue"),
  },
  {
    name: "DealerDashboardPage",
    path: "/dealer/dashboard",
    component: () => import("@/pages/DealerDashboardPage.vue"),
  },
  {
    name: "İşlem Kaydı",
    path: "/servisislemkaydi",
    component: () => import("@/pages/Services/NewServices"),
  },
  {
    name: "NewServiceRecordPage",
    path: "/services/new_service_record",
    component: () => import("@/pages/Services/NewServicePage.vue"),
  },
  {
    name: "ServiceUpdatePage",
    path: "/services/service_update/:id",
    component: () => import("@/pages/Services/ServiceUpdatePage.vue"),
  },
  {
    name: "ServiceDetailPage",
    path: "/services/service_detail/:id",
    component: () => import("@/pages/Services/ServiceDetailPage.vue"),
  },
  {
    name: "WaitingProcessListPage",
    path: "/services/waiting_processes",
    component: () => import("@/pages/Services/WaitingProcesses/ListPage.vue"),
  },
  {
    name: "CompletedProcessListPage",
    path: "/services/completed_processes",
    component: () => import("@/pages/Services/CompletedProcesses/ListPage.vue"),
  },
  {
    name: "InventoryListPage",
    path: "/inventories",
    component: () => import("@/pages/Inventories/ListPage"),
  },
  {
    name: "InventoryDetailPage",
    path: "/inventory/detail/:id",
    component: () => import("@/pages/Inventories/DetailPage"),
  },
  {
    name: "InventoryAddPage",
    path: "/inventory/add",
    component: () => import("@/pages/Inventories/AddPage"),
  },
  {
    name: "InventoryStatusListPage",
    path: "/inventory_status",
    component: () => import("@/pages/Inventories/InventoryStatus/ListPage.vue"),
  },
  {
    name: "InventoryStatusAddPage",
    path: "/inventory_status/add",
    component: () => import("@/pages/Inventories/InventoryStatus/AddPage.vue"),
  },
  {
    name: "InventoryStatusEditPage",
    path: "/inventory_status/edit/:id",
    component: () => import("@/pages/Inventories/InventoryStatus/EditPage.vue"),
  },
  {
    name: "InventoryBrandListPage",
    path: "/inventory_brands",
    component: () => import("@/pages/Inventories/Brands/ListPage.vue"),
  },
  {
    name: "InventoryBrandAddPage",
    path: "/inventory_brand/add",
    component: () => import("@/pages/Inventories/Brands/AddPage.vue"),
  },
  {
    name: "InventoryBrandEditPage",
    path: "/inventory_brand/edit/:id",
    component: () => import("@/pages/Inventories/Brands/EditPage.vue"),
  },
  {
    name: "InventoryMeasurementListPage",
    path: "/inventory_measurements",
    component: () => import("@/pages/Inventories/Measurements/ListPage.vue"),
  },
  {
    name: "InventoryMeasurementAddPage",
    path: "/inventory_measurement/add",
    component: () => import("@/pages/Inventories/Measurements/AddPage.vue"),
  },
  {
    name: "InventoryMeasurementEditPage",
    path: "/inventory_measurement/edit/:id",
    component: () => import("@/pages/Inventories/Measurements/EditPage.vue"),
  },
  {
    name: "InventoryProductListPage",
    path: "/inventory_products",
    component: () => import("@/pages/Inventories/Products/ListPage.vue"),
  },
  {
    name: "InventoryProductAddPage",
    path: "/inventory_product/add",
    component: () => import("@/pages/Inventories/Products/AddPage.vue"),
  },
  {
    name: "InventoryProductEditPage",
    path: "/inventory_product/edit/:id",
    component: () => import("@/pages/Inventories/Products/EditPage.vue"),
  },
  {
    name: "InventoryCardStockListPage",
    path: "/inventory_card_stocks",
    component: () => import("@/pages/Inventories/CardStocks/ListPage.vue"),
  },
  {
    name: "InventoryCardStockAddPage",
    path: "/inventory_card_stock/add",
    component: () => import("@/pages/Inventories/CardStocks/AddPage.vue"),
  },
  {
    name: "InventoryCardStockEditPage",
    path: "/inventory_card_stock/edit/:id",
    component: () => import("@/pages/Inventories/CardStocks/EditPage.vue"),
  },
  {
    name: "PageAddPage",
    path: "/page/add",
    component: () => import("@/pages/Authorities/PageAddPage"),
  },
  {
    name: "UserListPage",
    path: "/users",
    component: () => import("@/pages/Authorities/UserListPage"),
  },
  {
    name: "UserAddPage",
    path: "/user/add",
    component: () => import("@/pages/Authorities/UserAddPage"),
  },
  {
    name: "UserEditPage",
    path: "/user/edit/:id",
    component: () => import("@/pages/Authorities/UserEditPage"),
  },
  {
    name: "UserPasswordChangeAdminPage",
    path: "/user/password_change_admin/:id",
    component: () =>
      import("@/pages/Authorities/UserPasswordChangeAdminPage.vue"),
  },
  {
    name: "RoleListPage",
    path: "/roles",
    component: () => import("@/pages/Authorities/RoleListPage"),
  },
  {
    name: "RoleAddPage",
    path: "/role/add",
    component: () => import("@/pages/Authorities/RoleAddPage"),
  },
  {
    name: "RolePermissionEdit",
    path: "/permission/role_edit/:id",
    component: () => import("@/pages/Authorities/RolePermissionEditPage"),
  },
  {
    name: "UserPermissionEdit",
    path: "/permission/user_edit/:id",
    component: () => import("@/pages/Authorities/UserPermissionEditPage"),
  },
  {
    name: "TravelRoutePage",
    path: "/travel/:travelid",
    component: () => import("@/pages/Vehicles/RoutePage"),
  },
  {
    name: "TariffListPage",
    path: "/tariffs",
    component: () => import("@/pages/Tariffs/ListPage"),
  },
  {
    name: "TariffAddPage",
    path: "/tariff/add",
    component: () => import("@/pages/Tariffs/AddPage"),
  },
  {
    name: "TariffHistoryListPage",
    path: "/tariff_histories",
    component: () => import("@/pages/TariffService/HistoryListPage"),
  },
  {
    name: "TariffMatchPage",
    path: "/tariff_match",
    component: () => import("@/pages/TariffService/TariffMatchPage"),
  },
  {
    name: "ApproveImageListPage",
    path: "/pending_approval_images",
    component: () => import("@/pages/ApproveImageListPage.vue"),
  },
  {
    name: "PasswordChangePage",
    path: "/password_change",
    component: () => import("@/pages/PasswordChange.vue"),
  },
  {
    name: "TaximeterDataDateListPage",
    path: "/taximeter_data_dates",
    component: () => import("@/pages/TaximeterDataListPage.vue"),
  },
  {
    name: "ErrorPage",
    path: "/error",
    component: () => import("@/pages/ErrorPage.vue"),
  },
  {
    name: "404Page",
    path: "/:catchAll(.*)", // Herhangi bir yol ile eşleşecek
    component: () => import("@/pages/404Page"), // 404 sayfası
  },
];
const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
router.beforeEach(async (to, _, next) => {
  const userStore = useUserStore();
  const _isAuth = userStore.isAuthenticated;
  if (!_isAuth && to.fullPath != "/login") {
    next("/login");
  } else {
    if (to.fullPath === "/login") return next();
    const path = to.matched[0].path;
    const pathResponse = await userStore.getPagesByPathAction(path);
    if (pathResponse.statusCode === 200) {
      const checkAuth = await userStore.checkUserAuthLevelAction({
        pageID: pathResponse.data.pageID,
      });
      if (checkAuth.statusCode === 200) {
        if (checkAuth.data.permissionName !== "Unauthorized") {
          next();
        } else {
          next({ name: "ErrorPage" });
        }
      } else {
        next({ name: "ErrorPage" });
      }
    } else {
      next();
    }
    // next();
  }
});
export default router;
